import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
class RegistrationDecline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      error_type: null,
      emailValue: "",
      success: false
    }
    this.declineEmail = this.declineEmail.bind(this);
  }


  declineEmail(e) {
    e.preventDefault()
    const { emailValue } = this.state;
    fetchAPI(`/registration/decline`,(json)=>{
      if(json && json.error == null){
        this.setState({
          success: true
        });
      }else if(json && json.error) {
        console.log('in here');
        this.setState({
          error: json.error,
          error_type: json.type
        })
      }else {
        this.setState({
          error: json.error,
          error_type: json.type
        })
      }
    },{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }

  renderError() {
    const { error, error_type } = this.state;
    if (error) {
      let error_message = <></>;
      switch(error_type) {
        case "registered":
          error_message = (
            <div className="att-proam-registration-email-validation-error body2" style={{padding: "0px 0px"}}>
              Our records indicate that you have previously registered for this event. To cancel your attendance, please contact <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202025%20AT%26T%20Pebble%20Beach%20Pro-Am">executiverelations@cisco.com</a>. Thank you.
            </div>
          );
          break;
        case "not_found":
          error_message = (
            <div className="att-proam-registration-email-validation-error body2" style={{padding: "0px 0px"}}>
              Thank you for your interest in attending the 2025 AT&T Pebble Beach Pro-Am. As this is an invitation only event, please ensure the email address used matches the invitation. Please contact <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202025%20AT%26T%20Pebble%20Beach%20Pro-Am">executiverelations@cisco.com</a> for assistance.
            </div>
          );
          break;
        default:
          return <></>
      }
      return error_message;
    }
    return <></>;
  }

  render() {
    const { emailValue, error, success, date} = this.state;
    if(success) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: '60px'}}>
              <h4>Thank you for confirming your response.</h4>
            </div>
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: '24px'}}>
              <div className="body1">
                If you have any questions, please contact the Cisco Executive Events Team.
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <div className="att-proam-registration-email-validation-submit closed-page-button" style={{marginTop: '30px', width: 'fit-content'}}>
              <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202025%20AT%26T%20Pebble%20Beach%20Pro-Am">
                <div className="sg-button sg-button-secondary" type="button">
                  Contact Us
                </div>
              </a>
            </div>
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <form src="#" onSubmit={this.declineEmail} className="att-proam-registration-email-validation registration-form ">
          <div className="col-xs-12">
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: '60px'}}>
              <h4>Thank you for your response.</h4>
            </div>
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: '24px'}}>
              <h6>Sorry we will miss you at the 2025 AT&T Pebble Beach Pro-Am. We look forward to hosting you at a future Cisco event. To confirm your response, please enter your email address below.</h6>
            </div>
            <div className="att-proam-registration-email-validation-fields">
              <br/>
              <div className="label-wrapper">
                <label className="label-english">Enter Email Address *</label>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <input
              value={emailValue}
              onChange={(e) => this.setState({
                emailValue: e.target.value
              })}
              className="form-control"
            />
            <br />
          </div>
          <div className="col-xs-12"></div>
          <div className="col-xs-12 col-md-6" style={{textAlign: "left"}}>
            {this.renderError()}
          </div>
          <div className="col-xs-12">
            <div className="att-proam-registration-email-validation-submit">
              <br/>
              <button
                type="button"
                onClick={this.declineEmail}
                className="sg-button sg-button-secondary"
              >
                Continue
              </button>
            </div>
          </div>

          <div className="col-xs-12">
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: '36px'}}>
              <div className="body1">
                If you have any questions, please <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202025%20AT%26T%20Pebble%20Beach%20Pro-Am">contact</a> the Cisco Executive Events Team.
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>

        </form>
      </div>
    );
  }
}

export default RegistrationDecline;
